export const environment = {
    production: false,
    urls: {
        api: 'https://api.n2g-iona.net/v2/',
        auth: 'https://iona-test-live.wla-backend.de/auth',
        faq: 'https://iona-dev.wla-backend.de/faq/iona/?showNav=true',
        rewriteUrl: '',
    },
    isStaging: false
};


